body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #211f1f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input, textarea, button {
  /* -webkit-appearance: none;
  -webkit-border-radius: 0; */
}

.dropdown-toggle::after {
    display:none;
}

/* PullToRefresh style */
.ptr--icon, .ptr--text {
  color: white !important
}

.shake {
  /* Start the shake animation and make the animation last for X seconds */
  animation: shake 0.5s;
  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
    0% {
        transform: rotate(-2deg);
        animation-timing-function: ease-in;
    }
    50% {
        transform: rotate(3deg);
        animation-timing-function: ease-out;
    }
}
